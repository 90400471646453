import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Layout from '../../layouts/Base'
import {ProjectListArchive} from '../../components/ProjectList'

import background from '../../assets/images/karrabing/background.png'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '100px 200px 0px 200px',
    [theme.breakpoints.down('lg')]: {
      margin: '100px 200px 0px 200px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '100px 90px 0px 90px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '140px 30px 0px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '140px 20px 0px 20px',
    },
  },
}));

const projects = [
  {
    name: 'Nelson Makengo, Up at Night, 14 May - 10 June 2021',
    to: '/archive/nelson-makengo',
  },
  {
    name: 'Kani Marouf, Images of a Favour, 11 June - 15 July 2021',
    to: '/archive/kani-marouf',
  },
  {
    name: 'Arash Nassiri, City of Tales, 16 July - 12 August 2021',
    to: '/archive/arash-nassiri',
  },
  {
    name: 'Pauline Curnier-Jardin, Explosion Ma Baby, 13 August - 3 September 2021',
    to: '/archive/pauline-curnier-jardin',
  },
  {
    name: 'Camila Beltrán, Pacífico Oscuro, 4 September - 1 October 2021',
    to: '/archive/camila-beltran',
  },
  {
    name: 'Abtin Sarabi, Parcelles 7, 1 October - 25 October 2021',
    to: '/archive/abtin-sarabi',
  },
  {
    name: 'Karrabing Film Collective, The Mermaids, or Aiden in Wonderland, 25 October - 21 November 2021',
    to: '/archive/karrabing',
  },
]

export default function Page(props) {
  const classes = useStyles()

  return (
    <Layout page='archive' background={background}>
      <div
        className={classes.root}
      >
      <ProjectListArchive
        projects={projects}
      />
      </div>
    </Layout>
  )
}
